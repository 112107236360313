import * as React from "react"
import Lottie from "react-lottie"
import * as animationData from "../components/lottie/404.json"

import Layout from "../components/layout"
import MetaData from "../components/metadata"
import { Box } from "@mui/material"
import { useMediaQuery } from "@material-ui/core"

const NotFoundPage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  const matches = useMediaQuery('(min-width:600px)');

  return <Layout>
    <MetaData title={"404: Nem található"} url={"/404"} description={"Az oldal nem található"} />
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: {xs: '40px 0px', md: '80px 0px'},
      height: '65vh'
    }}>
      <Box sx={{ maxWidth: "700px", maxHeight: matches ? "320px" : "240px" }}>
        <Lottie options={defaultOptions}
                height={matches ? 480 : 320}
                width={"100%"} />
      </Box>
      <h1 style={{
        width: "100%",
        textAlign: 'center'
      }}>Az oldal nem található</h1>
    </Box>
  </Layout>
}

export default NotFoundPage
